export const ENDPOINTS = {
  GENERATEJWT: "generate_jwt",
  FILENAMETOBASE64: "binaryfiledatabypath",

  // dropdown options
  SYSTEM_ROLE_LIST: "get_system_role",
  OPERATOR_LIST: "get_operators",
  ORG_ROLE_LIST: "get_org_role",
  ZONE_LIST: "get_zones",
  REGION_LIST: "get_regions",

  // authenticaiton api's start
  LOGIN: "user/login",
  ADDNEWPASSWORD: "userpassword/addnewpassword",
  FORGOTPASSWORD: "forgotpassword",
  FORGOTUSERID: "forgotuserid",

  // add new users
  ADD_USER: "post_user_submit",
  USER_DETAILS: "get_user_details",
  APPROVE_PENDING_USER: "post_user_approve",
  REJECT_PENDING_USER: "post_user_reject",
  PENDING_USER_LIST: "get_pending_approval_userlist",

  // search & update existing users
  SEARCH_USER: "search_user",
  UPDATE_USER: "post_user_edit",
  USER_STATUS_UPDATE: "post_user_status_update",
  USER_PROFILE_DETAILS: "get_user_profile_details",
  PENDING_PROFILE_LIST: "get_user_profile_edits",
  APPROVE_PROFILE_EDIT: "post_user_edit_approval",
  REJECT_PROFILE_EDIT: "post_user_edit_reject",

  // driver api's start
  DRIVERONOARDING: "tr_driver/getdriverapplied",
  DRIVERADDRESSDETAILBYID: "tr_driver/getdriverdetails",
  DRIVERDETAILBYID: "tr_driver/getdlbydriverid",

  // driver DL verification
  APLICATIONHOLD: "applicationhold/posthold",
  REJECTDRIVERAPP: "applicationreject/rejectdriverapplication",
  HOLDDRIVERAPP: "applicationhold/holddriverdl",
  GETREJECTIONLIST: "driverrejectionreason/getrejectionreason",
  GETLanguageList: "getlanguage",
  VERIFYDLSTATUS: "applicationhold/verifydlstatus",

  //driver address
  GETADDRESS: "autoapproval/getaddress",
  HOLDDRIVERAPPLICAION: "applicationhold/holddriverapplication",
  REJECTDRIVERAPPLICATION: "applicationreject/rejectdriverapplication",
  SUBMITDRIVERAPPLICATION: "autoapproval/submitdriverapplication",
  AUTOAPPROVEDRIVER: "autoapproval/autoapprovedriver",
  GETDETAILBYPINCODE: "md_pincode/getdistrictstatename",
  ADDRESSDOCLIST: "addressproofdoclist",

  // driver approval
  GETDRIVERAPPROVAL: "tr_driver/getdriverdetailsforapproval",
  DRIVERAPPROVEDETAILBYID: "tr_driver/getdriverdetails",
  APPROVEDRIVER: "autoapproval/manualapprovedriver",
  RETURNDRIVERAPPROVE: "autoapproval/returnupdatesubstatus",

  //vehicle approval queue
  ACTIVEDRIVERVEHICLELIST: "vehicleattachment/activedriverappliedvehiclelist",
  VEHICLEDETAILBYID: "vehicleattachment/getvehicleandrcdetailsbycode",
  RCHOLD: "rcverfication/rchold",
  REJECTIONLIST: "rcverfication/getallreason",
  RECREJECT: "rcverfication/rcreject",
  VERIFYRC: "rcverfication/verifyrc",

  //insurance detail
  GETINSURANCECOMPANYLIST: "insurancedetails/getinsurancecompanylist",
  UPDATEINSURANCEVALIDITY: "insurancedetails/updateinsurancevalidity",

  //account detail
  POSTBANKDETAIL: "bankaccount/postSubmit",
  POSTHOLDACCOUNT: "bankaccount/postHold",

  //permit detail
  POSTPERMITDETAILS: "permitdetails/postpermitdetails",

  //vehicle verification
  HOLDVEHICLE: "vehicleverification/holdvehicleverification",
  VEHICLEREJECT: "vehicleverification/vehiclereject",

  //vehicle approval queue
  GETVEHICLEAPPROVALLIST: "vehicleapprovalqueue/getVehiclesPedningForApproval",
  BANKDETAILS: "bankaccount/getBankDetails",

  // rc verification
  POSTMANUALVEHICLEAPPROVAL: "vehicleverification/manualvehicleapproval",
  GETEMISSION: "get/emission/norms",

  // emission certificate verification
  PUTEMISSIONDETAILS: "bankaccount/submit",

  //meter and cng certificate verification
  POSTVEHICLEMETER:"meter_certificate_submit",
  POSTVEHICLECNG:"cngtankfc/submit",


  //vehicle approval
  POSTVEHICLEAPPROVAL: "validateandapprove/postApprove",
  POSTAPPROVEWOBANK: "approve/without_bankaccount",

  //tax detail
  POSTVEHICLETEX: "vehicletax/postSubmit",
  PUTRETURN: "validateandapprove/postReturn",

  //driver verification
  DRIVERDL: "dl",
  VEHICLERC: "rc",

  // complaints
  COMPLAINT_TYPES: "portal/get_complaint_category",
  CUST_COMPLAINT_LIST: "get_customer_complaints",
  CUST_COMPLAINT_DETAIL: "customer/complaint_details",
  CUST_UPDATE_COMPLAINT_DETAIL: "customer/update_complaint",
  CUST_UPDATE_COMPLAINT_STATUS: "customer/close_or_reject_complaint",

  //DRIVER_CREATE_COMPLAINT: '',
  DRIVER_COMPLAINT_LIST: "get_driver_complaints",
  DRIVER_COMPLAINT_DETAIL: "driver/complaint_details",
  DRIVER_UPDATE_COMPLAINT_DETAIL: "driver/update_complaint",
  DRIVER_UPDATE_COMPLAINT_STATUS: "driver/close_or_reject_complaint",

  // support tickets
  CUST_TICKET_LIST: "get_customer_support_tickets",
  // CUST_CREATE_TICKET: 'customer/create_support_ticket',
  CUST_TICKET_DETAIL: "customer/support_ticket_details",
  CUST_UPDATE_TICKET: "customer/update_support_ticket",
  CUST_CLOSE_TICKET: "customer/close_support_ticket",

  DRIVER_TICKET_LIST: "get_driver_support_tickets",
  // DRIVER_CREATE_TICKET: 'driver/create_support_ticket',
  DRIVER_TICKET_DETAIL: "driver/support_ticket_details",
  DRIVER_UPDATE_TICKET: "driver/update_support_ticket",
  DRIVER_CLOSE_TICKET: "driver/close_support_ticket",

  // driver search & details
  DRIVER_SEARCH: "search_driver",
  DRIVER_DETAIL: "driver/get_details",
  DRIVER_TICKETS: "driver/support_ticket_list",
  DRIVER_COMPLAINTS: "driver/complaint_ticket_list",
  DRIVER_COMPLAINT_ACTIONS: "driver/action_taken",
  DRIVER_COMPLAINTS_AGAINST: "driver/complaints",
  DRIVER_LINKED_VEHICLES: "vehicle/get_list_for_driver",
  DRIVER_COMPLETED_RIDES: "driver/completedride_list",
  DRIVER_ACTIVE_RIDES: "driver/activeride_list",
  DRIVER_BLACKLIST: "driver/suspend_or_blacklist",
  DRIVER_BLACKLIST_REVOKE: "driver/revoke_suspension_or_blacklist",
  MANUAL_DOCUMENTS_IMAGE: "manual_verify_image",
  //DRIVER_ACTION_LIST: 'driver/action_taken',
  DRIVER_NEW_TICKET: "driver/create_support_ticket",
  DRIVER_NEW_COMPLAINT: "portal/raise_complaint",
  DRIVER_RIDE_TXN: "driver/transaction_details",
  DRIVER_REFUNDS: "driver/",
  DRIVER_BANK_DETAILS: "portal/get_driver_bank_details",
  DRIVER_PAYOUT: "portal/driver_payout",

  //customer search & details
  CUST_SEARCH: "search_customer",
  CUST_DETAIL: "customer/get_details",
  CUST_TICKETS: "customer/support_ticket_list",
  CUST_COMPLAINTS: "customer/complaint_ticket_list",
  CUST_COMPLAINTS_AGAINST: "customer/complaints",
  CUST_COMPLAINT_ACTIONS: "customer/action_taken",
  CUST_COMPLETED_RIDES: "customer/completedride_list",
  CUST_ACTIVE_RIDES: "customer/activeride_list",
  CUST_BLACKLIST: "customer/suspend_or_blacklist",
  CUST_BLACKLIST_REVOKE: "customer/revoke_suspension_or_blacklist",
  CUST_NEW_TICKET: "customer/create_support_ticket",
  CUST_NEW_COMPLAINT: "portal/raise_complaint",
  CUST_RIDE_TXN: "customer/transaction_details",
  CUST_REFUNDS: "customer/get_refund_complaints",

  // vehicle search & details
  VEHICLE_SEARCH: "vehicle/search",
  VEHICLE_DETAIL: "vehicle/get_details",
  VEHICLE_ACTION_LOG: "vehicle/action_taken",
  VEHICLE_BLACKLIST: "vehicle/suspend_or_blacklist",
  VEHICLE_BLACKLIST_REVOKE: "vehicle/revoke_suspension_or_blacklist",

  // vehicle document approval queue
  VEHICLE_PENDING_DOC_QUEUE: "vehicle/get_document_list",
  VEHICLE_PENDING_DOC_DETAIL: "vehicle/get_document_details",
  VEHICLE_PENDING_DOC_REJECT: "portal/reject",
  VEHICLE_DOC_EMISSION_UPDATE: "portal/emission_update",
  VEHICLE_DOC_RC_UPDATE: "portal/rc_update",
  VEHICLE_DOC_INSURANCE_UPDATE: "portal/insurance_update",
  VEHICLE_DOC_PERMIT_UPDATE: "portal/permit_update",
  VEHICLE_DOC_TAX_UPDATE: "portal/vehicletax_update",

  // driver document approval queue
  DRIVER_PENDING_DOC_QUEUE: "driver/get_document_list",
  DRIVER_PENDING_DOC_DETAIL: "driver/get_document_details",
  DRIVER_PENDING_DOC_REJECT: "portal/reject",
  DRIVER_DOC_PAN_UPDATE: "portal/pan_update",
  DRIVER_DOC_DL_UPDATE: "portal/dl_update",
  DRIVER_DOC_ADDRESS_UPDATE: "portal/address_update",
  //Qr Mapping
  POST_QR_Details:"portal/qrcode_mapping",

  //get documents
  GET_DOCUMENTS:"getdoc",
  GET_INFORMATION:"get_info",
  UPDATE_INFORMATION:"editinfo",
  UPDATE_DOCUMENT:"uploaddoc",

  REPORT_GST_TXN: "portal/gst_transaction_report",
  REPORT_DRIVER_PAYOUT: "portal/get_payout_details",
  REPORT_CREDIT_NOTE: "portal/credit_note_report",

  FINANCE_ENTITY_DETAILS: "portal/get_driver_customer_details",
  FINANCE_CREDIT_ACCOUNT: "portal/account_credit",
  FINANCE_DEBIT_ACCOUNT: "portal/account_debit",

  ACCOUNT_TXN: "portal/get_account_transaction",
  RIDE_INVOICE: "rides/get_invoice",
  RIDE_DETAILS: "rides/get_details",

  RIDE_TXNS: "portal/transaction_details_byid",
  RIDE_REFUND: "portal/process_refund",

  Terminate_Customer_Or_Driver: "portal/terminate/customer_or_driver",
  Terminate_Vehicle: "vehicle/terminate",

  Available_vehicles: "portal/getvehicles",

  getState: "getstatelist",
  getDistrictbyState: "getdistrictbystatecode",
  getcity: "getcitylistbydistrictcode",
  getVehicleTypelist: "vehicletype/getall",
  getDemandandsupply: "portal/get_demandandsupply",
};

export const JUURIDE_VARS = {
  GOOGLE_API_KEY: "AIzaSyB0QY1TT33F-B588UZj7etJvgDJy6TNqBo",

  DRIVER_SUPPORT_TICKET_TYPE: 50,
  CUST_SUPPORT_TICKET_TYPE: 51,

  DRIVER_BIZ_ENTITY_TYPE: 3,
  CUST_BIZ_ENTITY_TYPE: 4,

  DRIVER_COMPLAINT_TYPE: 13,
  CUST_COMPLAINT_TYPE: 16,

  COMPLAINT_REJECTED: 3,
  COMPLAINT_CLOSED: 4,
  CUST_COMPLAINT_CHARGE_REVERSAL: 14,
  CUST_COMPLAINT_OTHERS: 16,

  DRIVER_COMPLAINT_CHARGE_REVERSAL: 10,
  DRIVER_COMPLAINT_OTHERS: 13,

  DL_REJECT_MSGID: 417,
  PAN_REJECT_MSGID: 411,
  ADDRESS_REJECT_MSGID: 418,
  RC_REJECT_MSGID: 416,
  EMISSION_REJECT_MSGID: 415,
  INSURANCE_REJECT_MSGID: 414,
  PERMIT_REJECT_MSGID: 413,
  TAX_REJECT_MSGID: 412,
  FC_REJECT_MSGID: 419,
  CNG_FC_REJECT_MSGID: 420,
  MERIT_REJECT_MSGID: 421,

  CREDIT_ACCOUNT_TXNID: 29,
  DEBIT_ACCOUNT_TXNID: 30,

  START_YEAR_OPTION: 2020,
  MONTH_OPTIONS: [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "August" },
    { value: 9, text: "September" },
    { value: 10, text: "October" },
    { value: 11, text: "November" },
    { value: 12, text: "December" },
  ],

  VEHICLE_PIN: {
    path: "M 14, 14m -7, 0a 7,7 0 1,0 14,0a 7,7 0 1,0 -14,0",
    fillColor: "#0000ff",
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 1,
  },

  MAP_TYPE: "roadmap",

  MAP_STYLES: [
    {
      featureType: "poi.medical",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#838383",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "labels.icon",
      stylers: [
        {
          color: "#5c5c5c",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

export const JUURIDE_MESSAGES = {
  SUPPORT_TICKET_ADDED: "Support Ticket added successfully",
  SUPPORT_TICKET_SUCCESS: "Support Ticket updated successfully.",
  SUPPORT_TICKET_CLOSED: "Support Ticket Closed.",

  COMPLAINT_ADDED: "Complaint added successfully.",
  COMPLAINT_SUCCESS: "Complaint updated successfully",
  COMPLAINT_CLOSED: "Complaint Closed.",
  COMPLAINT_REJECTED: "Complaint Rejected.",
};
// export const ImgUrls = {
//   1: "assets/images/logo/juuride-logo.png",
//   2: "https://metromitra.fra1.digitaloceanspaces.com/report-logo/metromitra.jpg",
//   3: "assets/images/logo/juuride-logo.png",
//   4: "assets/images/logo/juuride-logo.png",
//   5: "assets/images/logo/juuride-logo.png",
//   6: "https://metromitra.fra1.digitaloceanspaces.com/report-logo/Pune%20Autos.jpg",
//   7: "https://metromitra.fra1.digitaloceanspaces.com/report-logo/eSamudaay_Ride_App_Icon..png",
// };

// Use below for Prod
export const LOGIN_BASE_URL = "https://microservices.metromitra.co.in/";
export const DRIVER_BASE_URL = "https://microservices.metromitra.co.in/";
export const VEHICLE_BASE_URL = "https://microservices.metromitra.co.in/";

export const DRIVERVERIFICATION = "http://localhost:5000/";

export const ACCESS_TOKEN = "access_token";

export const JWT_BASE_URL_ENDPOINT = 'jmprodnwebtokens_jwt/jmdevnwebtokens/';
export const LOGIN_BASE_URL_ENDPOINT = 'jmprodnusrlogin_jwt/jmdevnusrlogin/';
export const ADMIN_BASE_URL_ENDPOINT = 'jmprodnusrlogin/jmdevnusrlogin/';

export const DRIVER_BASE_URL_ENDPOINT = 'jmprodndrvvehonboard/jmdevndrvvehonboard/';
export const VEHICLE_BASE_URL_ENDPOINT = 'jmprodnvehreg/jmdevnvehreg/';

export const DRIVER_BASE_URL_ENDPOINT_PYTHON = 'jmprodpdrvvehonboard/jmdevpdrvvehonboard/';
export const VEHICLE_BASE_URL_ENDPOINT_PYTHON = 'jmprodpvehreg/jmdevpvehreg/';

export const SUPPORT_BASE_URL_ENDPOINT = 'jmprodpsupoorts/jmdevpsupoorts/';
export const CITY_BASE_URL_ENDPOINT='jmprodndrvreg_jwt/jmdevndrvreg/';
export const SUPPORT_BASE_URL_ENDPOINT_JWT='jmprodpsupoorts_jwt/jmdevpsupoorts/'
export const GET_DOCUMENTS_CODE_BASE_URL_ENDPOINT= 'jmprodpdrvreg/jmdevpdrvreg/';

// For Dev use the below
// export const LOGIN_BASE_URL = "https://dev.juuride.com/";
// export const DRIVER_BASE_URL = "https://dev.juuride.com/";
// export const VEHICLE_BASE_URL = "https://dev.juuride.com/";

// export const DRIVERVERIFICATION = "http://localhost:5000/";

// export const ACCESS_TOKEN = "access_token";

// export const JWT_BASE_URL_ENDPOINT = "jmdevnwebtokens_jwt/jmdevnwebtokens/";
// export const LOGIN_BASE_URL_ENDPOINT = "jmdevnusrlogin_jwt/jmdevnusrlogin/";
// export const ADMIN_BASE_URL_ENDPOINT = "jmdevnusrlogin/jmdevnusrlogin/";

// export const DRIVER_BASE_URL_ENDPOINT =
//   "jmdevndrvvehonboard/jmdevndrvvehonboard/";
// export const VEHICLE_BASE_URL_ENDPOINT = "jmdevnvehreg/jmdevnvehreg/";

// export const DRIVER_BASE_URL_ENDPOINT_PYTHON =
//   "jmdevpdrvvehonboard/jmdevpdrvvehonboard/";
// export const VEHICLE_BASE_URL_ENDPOINT_PYTHON = "jmdevpvehreg/jmdevpvehreg/";

// export const SUPPORT_BASE_URL_ENDPOINT = "jmdevpsupoorts/jmdevpsupoorts/";
// export const CITY_BASE_URL_ENDPOINT = "jmdevndrvreg_jwt/jmdevndrvreg/";
// export const SUPPORT_BASE_URL_ENDPOINT_JWT ="jmdevpsupoorts_jwt/jmdevpsupoorts/";
//export const GET_DOCUMENTS_CODE_BASE_URL_ENDPOINT= 'jmdevpdrvreg/jmdevpdrvreg/';
