import { Component, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast/toast.service';

import { FinanceService } from 'src/app/components/finance/finance.service';

@Component({
  selector: 'account-transaction',
  templateUrl: './account-transaction.component.html',
  styleUrls: ['./account-transaction.component.css']
})
export class AccountTransactionComponent implements AfterViewInit {
  @Input() holderId: any;
  @Input() bizEntityType: any;
  @Output() closeModal = new EventEmitter();

  private accountTxn:any = null;

  constructor(
    private toastService: ToastService,
    private financeService: FinanceService,
    private ngxSpinnerService: NgxSpinnerService) { }

  ngAfterViewInit() {
    this.getAccountTxnDetails();
  }

  // get ride invoice
  getAccountTxnDetails() {
   this.ngxSpinnerService.show();

    var payload = {
      businessentitytypecode: this.bizEntityType,
      holderid: this.holderId
    };

    this.financeService.getAccountTransaction(payload).subscribe(response => {
      this.accountTxn = response;
      this.ngxSpinnerService.hide();
    },
    error => {
      this.ngxSpinnerService.hide();
      this.toastService.showToaster('Account Transaction', error.error.message, 'error');
    });
  }

  // calling parent function to close modal
  closeTxnModal() {
    this.closeModal.emit();
  }

}
