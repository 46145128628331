import { Component, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast/toast.service';

import { RidesService } from 'src/app/components/rides/rides.service';

@Component({
  selector: 'ride-invoice',
  templateUrl: './ride-invoice.component.html',
  styleUrls: ['./ride-invoice.component.css']
})
export class RideInvoiceComponent implements AfterViewInit {
  @Input() rideId: any;
  @Output() closeModal = new EventEmitter();

  private rideInvoice: any = null;
  private showRideInvoice: boolean = false;

  constructor(
    private toastService: ToastService,
    private rideService: RidesService,
    private ngxSpinnerService: NgxSpinnerService) { }

  ngAfterViewInit() {
    this.getRideInvoice();
  }

  // get ride invoice
  getRideInvoice() {
   this.ngxSpinnerService.show();

    var payload = {
      rideid: this.rideId
    };

    this.rideService.getRideInvoiceApi(payload).subscribe(response => {
      this.rideInvoice = response;
      this.ngxSpinnerService.hide();
    },
    error => {
      this.ngxSpinnerService.hide();
      this.toastService.showToaster('Ride Invoice', error.error.message, 'error');
    });
  }

  // calling parent function to close modal
  closeInvoiceModal() {
    this.closeModal.emit();
  }
}
