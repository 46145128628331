import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LOGIN_BASE_URL, ACCESS_TOKEN, DRIVER_BASE_URL, VEHICLE_BASE_URL, DRIVERVERIFICATION } from '../app.constants';
import { catchError, retry, finalize } from "rxjs/operators";


@Injectable()
export class JuuRideAppInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log(req.url);

    if(req.url.includes('jmdevnusrlogin_jwt') || req.url.includes('jmdevnwebtokens')){     
      req = req.clone({
        url: LOGIN_BASE_URL + req.url
      });
    }
    else if(req.url.includes('jmdevnusrlogin')){
      req = req.clone({
        url: LOGIN_BASE_URL + req.url,
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
    }
    else if(req.url.includes('jmdevndrvvehonboard') || 
            req.url.includes('jmdevpdrvvehonboard') || 
              req.url.includes('jmdevnvehreg') || 
                req.url.includes('jmdevpsupoorts') ||
                req.url.includes('jmdevndrvreg') ||
                req.url.includes('jmdevpdrvreg')) {

      req = req.clone({
        url: DRIVER_BASE_URL + req.url,
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
    }
    else{
      req = req.clone({
        url: DRIVERVERIFICATION + req.url,
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
    }
    
    return next.handle(req)
    .pipe(
        catchError((error: HttpErrorResponse) => {
            return throwError(error);
        })
    );
    }
}
