import { Component, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast/toast.service';

import { RidesService } from 'src/app/components/rides/rides.service';

@Component({
  selector: 'ride-charge-detail',
  templateUrl: './ride-charge-detail.component.html',
  styleUrls: ['./ride-charge-detail.component.css']
})
export class RideChargeDetailComponent implements AfterViewInit {
  @Input() rideId: any;
  @Input() entityId: any;
  @Input() bizEntityType: any;
  @Output() closeModal = new EventEmitter();

  private rideCharges: any = null;

  constructor(
    private toastService: ToastService,
    private rideService: RidesService,
    private ngxSpinnerService: NgxSpinnerService) {}

  ngAfterViewInit() {
    this.getRideChargeDetails();
  }

  // get ride invoice
  getRideChargeDetails() {
    this.ngxSpinnerService.show();
    var payload = {};

    if(this.bizEntityType == 3) {
      //driver
      payload = {
        rideid: this.rideId,
        driverid: this.entityId
      };
    }
    else if(this.bizEntityType == 4) {
      // customer
      payload = {
        rideid: this.rideId,
        customerid: this.entityId
      };
    }

    this.rideService.getRideTxnDetails(this.bizEntityType, payload).subscribe(response => {
      this.rideCharges = response;
      this.ngxSpinnerService.hide();
    },
    error => {
      this.ngxSpinnerService.hide();
      this.toastService.showToaster('Ride Invoice', error.error.message, 'error');
    });
  }

  // calling parent function to close modal
  closeChargesModal() {
    this.closeModal.emit();
  }
}
