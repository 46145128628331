import { EventEmitter, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavItem } from './nav-item'

@Injectable()
export class NavService {
    public appDrawer: any;
    public isMobile: any;
    public currentUrl = new BehaviorSubject<string>(undefined);

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl.next(event.urlAfterRedirects);
            }
        });
    }

    public closeNav() {
        this.appDrawer.close();
    }

    public openNav() {
        this.appDrawer.open();
    }

    public getMfgMenu(): NavItem[] {
        return [
            {
                displayName: 'Administration',
                iconName: 'ti-user',
                children:[{
                    displayName: 'Create Department/Edit Department',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Create Team/Edit Team',
                    route: 'dashboard',
                },
                {
                    displayName: 'Create Team/Edit Role',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'User Create',
                    route: 'dashboard',
                },
                {
                    displayName: 'User Search/ Edit',
                    route: 'dashboard',
                },
                {
                    displayName: 'User Approval',
                    route: 'dashboard',
                }
                ]
            },

            {
                displayName: 'Sales',
                iconName: 'ti-home',
                children:[{
                    displayName: 'Agent Creation',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Agent Search',
                    route: 'dashboard',
                },
                {
                    displayName: 'Send Message - Agent',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Send Message to Driver',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Creation',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Search',
                    route: 'dashboard',
                },
                {
                    displayName: 'Send Message - Agency',
                    route: 'dashboard',
                }
                ]
            },

            {
                displayName: 'Inbound Advertisement',
                iconName: 'ti-home',
                children:[{
                    displayName: 'Ad Slot Query',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Upload Ad',
                    route: 'dashboard',
                },
                {
                    displayName: 'Schedule Ad',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Approve Scheduled Ad',
                    route: 'dashboard',
                },
                {
                    displayName: 'Ad Stats Report',
                    route: 'dashboard',
                },
                ]
            },

            {
                displayName: 'Agent Onboarding Servicing',
                iconName: 'ti-user',
                children:[{
                    displayName: 'Agent Approve',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Agent Change Accept',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Service Ticket',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Agent Closed Service Ticket',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Closed Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent  Search',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent  Team Report',
                    route: 'dashboard',
                },
                ]
            },

            {
                displayName: 'Agency Onboarding Servicing',
                iconName: 'ti-archive',
                children:[{
                    displayName: 'Agency Approve',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Agency Change Accept',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Service Ticket',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Agency Closed Service Ticket',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Closed Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Search',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Team Report',
                    route: 'dashboard',
                },
                ]
            },

            {
                displayName: 'Driver OnBoarding & Servicing',
                iconName: 'ti-home',
                children:[{
                    displayName: 'OnBoading Queue',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Changes Queue',
                    route: 'dashboard',
                },
                {
                    displayName: 'Approval Queue',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Driver Search',
                    route: 'dashboard',
                },
                {
                    displayName: 'Driver OnBording Report',
                    route: 'dashboard',
                }
                ]
            },

            {
                displayName: 'Vehicle Attachment & Servicing',
                iconName: 'ti-home',
                children:[{
                    displayName: 'Vehicle Atatchment Queue',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Vehicle Changes Queue',
                    route: 'dashboard',
                },
                {
                    displayName: 'Vehicle Changes Approval Queue',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Vehicle Attachment Approval Queue',
                    route: 'dashboard',
                },
                {
                    displayName: 'Vehicle Attachment Team Report',
                    route: 'dashboard',
                },
                {
                    displayName: 'Vehicle Make Master',
                    route: 'dashboard',
                },
                {
                    displayName: 'Vehicle Model Master',
                    route: 'dashboard',
                },
                ]
            },
            
            {
                displayName: 'Service Desk',
                iconName: 'ti-mobile',
                children:[{
                    displayName: 'Customer/Driver Search',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'Customer Service Ticket',
                    route: 'dashboard',
                },
                {
                    displayName: 'Customer Closed Service Ticket',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Customer Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Customer Closed Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Driver Service Ticket',
                    route: 'dashboard',
                },
                {
                    displayName: 'Driver Closed Service Ticket',
                    route: 'dashboard',
                },
                {
                    displayName: 'Driver Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Driver Closed Complaints',
                    route: 'dashboard',
                },
                {
                    displayName: 'Charge Refund',
                    route: 'dashboard',
                },
                {
                    displayName: 'Customer Service Report',
                    route: 'dashboard',
                },
                ]
            },
            
            {
                displayName: 'Finance',
                iconName: 'ti-home',
                children:[{
                    displayName: 'Agent Commission Feed',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'eSettlement Feed',
                    route: 'dashboard',
                },
                {
                    displayName: 'eSettlement Report',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Agent Commission Report',
                    route: 'dashboard',
                },
                {
                    displayName: 'Credit Recovery',
                    route: 'dashboard',
                },
                {
                    displayName: 'Credit Juu Ride Account',
                    route: 'dashboard',
                },
                {
                    displayName: 'Credit Juu Ride Account Approve',
                    route: 'dashboard',
                },
                ]
            },
             
            {
                displayName: 'Settings',
                iconName: 'ti-settings',
                children:[{
                    displayName: 'Ride Charge GST',
                    route: 'driver-dashboard',
                },
                {
                    displayName: 'ePayment Charge GST',
                    route: 'dashboard',
                },
                {
                    displayName: 'State Specific ePayment Charge GST',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'State Specific Ride Charge GST',
                    route: 'dashboard',
                },
                {
                    displayName: 'Create /Edit Ride Fee Base Value',
                    route: 'dashboard',
                },
                {
                    displayName: 'Create /Edit Referral Fee Base Value',
                    route: 'dashboard',
                },
                {
                    displayName: 'Create Ride Fee Discount',
                    route: 'dashboard',
                },
                {
                    displayName: 'Create Referral Fee Discount',
                    route: 'dashboard',
                },
                {
                    displayName: 'ABL Penalty',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Agent Recharge Service Charge Create/ Edit',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Recharge Service Charge Discount Create',
                    route: 'dashboard',
                },
                {
                    displayName: 'Offer Create/ Edit',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Recharge Fee Report',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Ride Max Rate Limit  Create /Edit',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Ride Commission Incentive',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Lead Purchase Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'Overall Business Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Specific Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Commission',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Prohibited Business List',
                    route: 'dashboard',
                },
                {
                    displayName: 'Ride Charge GST Approval',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'ePayment Charge GST Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'State Specific ePayment Charge GST Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'State Specific Ride Charge GST Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Fees and Charges',
                    route: 'dashboard',
                },

                {
                    displayName: 'Ride Fee Base Value Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Referral Fee Base Value Approval',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Ride Fee Discount Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Referral Fee Discount Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Special Commission Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agency Special Discount Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Ride Max Rate Limit Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Commission Min Days',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'City Ride Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'Lead Purchase Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'Overall Business Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'City Specific Commission Incentive',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Commission',
                    route: 'dashboard',
                },
                {
                    displayName: 'ABL Penalty Approval',
                    route: 'driver-dashboard/driver-address',
                },
                {
                    displayName: 'Agent Recharge Service Charge Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Recharge Service Charge  Discount approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Offer Approval',
                    route: 'dashboard',
                },
                {
                    displayName: 'Agent Recharge Fee Report',
                    route: 'dashboard',
                },{
                    displayName: 'Agent Recharge Service Fee Feed',
                    route: 'dashboard',
                },
                ]
            },
        ];
    }

}
