import { Component, OnInit } from '@angular/core';
import { NavItem } from './menu-item/nav-item';
import { NavService } from './menu-item/nav-item.service';
import { Router } from '@angular/router';
import { asEnumerable } from 'linq-es2015';
// import { ImgUrls } from '../app.constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './common-layout.component.html'
})

export class CommonLayoutComponent implements OnInit {

  public app : any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any = 'header-dark';
  public sidenavSelected : any;
  public searchActived : any;
  public searchModel: any;
  public userinfo:any;
  navItems: NavItem[] = [];
  public menuItems = [];

  constructor(private navService:NavService,private router: Router) {
    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: true,
        isMenuCollapsed: true,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };  

    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.changeHeader = changeHeader;
  
    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }
  
    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;
  
    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }

    var menuItems = JSON.parse(localStorage.getItem('userInfo')).Result;
    // console.log(JSON.parse(menuItems).Result);

    this.menuItems = asEnumerable(menuItems).GroupBy(
      x => x['groupname'], x => x, (key, b) => {
      return { displayName: key, children: asEnumerable(b).ToArray() } }).ToArray();

    // this.navItems = this.navService.getMfgMenu();
    this.navItems = this.menuItems;
    this.app.layout.isMenuCollapsed = false;
  }

  ngOnInit(){
    var logo=document.querySelector(".logo") as HTMLElement;
    this.userinfo=JSON.parse(localStorage.getItem('userInfo'));
    logo.style.backgroundImage=`url('${this.userinfo.operatorlogo}')`
  }

  logout(){
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userInfo');

    localStorage.removeItem('selectedDriverInfo')
    localStorage.removeItem('driverDocumentApproval');
    localStorage.removeItem('driverSearchCriteria');
    
    localStorage.removeItem('vehicleVerifiy');
    localStorage.removeItem('vehicleDocumentApproval');
    localStorage.removeItem('vehicleSearchCriteria');
    
    localStorage.removeItem('selectedCustomerInfo');
    localStorage.removeItem('customerSearchCriteria');
    
    localStorage.removeItem('userSearchCriteria');
    localStorage.removeItem('currentUserAction');
    localStorage.removeItem('currentUserProfile');
    
    this.router.navigate(['/']);
  }
}
