import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ENDPOINTS,
  ADMIN_BASE_URL_ENDPOINT,
  SUPPORT_BASE_URL_ENDPOINT } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private httpClient: HttpClient) { }

  // Get User Details
  getDetails(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.FINANCE_ENTITY_DETAILS}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get driver bank details
  getDriverBankDetails(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_BANK_DETAILS}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // Get User Details
  postCreditAmount(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.FINANCE_CREDIT_ACCOUNT}`, payload).pipe(map((data: any) => {
      return data;
    })); 
  }

  // Get User Details
  postDebitAmount(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.FINANCE_DEBIT_ACCOUNT}`, payload).pipe(map((data: any) => {
      return data;
    })); 
  }

  // Get Account Transaction
  getAccountTransaction(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+ `${ENDPOINTS.ACCOUNT_TXN}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  driverPayout(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+ `${ENDPOINTS.DRIVER_PAYOUT}`, payload).pipe(map((data: any) => {
      return data;
    }));
  }
}
