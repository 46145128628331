import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';



@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastyService: ToastyService) { }

  showToaster(title:string , msg:string , type:string){
    let toastOptions: ToastOptions = {
        title: title,
        msg: msg,
        showClose: true,
        timeout: 15000,
        theme: "bootstrap",
 
    };
    switch (type) {
        case 'default': this.toastyService.default(toastOptions); break;
        case 'info': this.toastyService.info(toastOptions); break;
        case 'success': this.toastyService.success(toastOptions); break;
        case 'wait': this.toastyService.wait(toastOptions); break;
        case 'error': this.toastyService.error(toastOptions); break;
        case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
  
}
