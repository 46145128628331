import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';

import { RideInvoiceComponent } from 'src/app/common/ride-invoice/ride-invoice.component';
import { RideChargeDetailComponent } from 'src/app/common/ride-charge-detail/ride-charge-detail.component';
import { AccountTransactionComponent } from 'src/app/common/account-transaction/account-transaction.component';


@NgModule({
  declarations: [
    AccountTransactionComponent,
    RideChargeDetailComponent,
    RideInvoiceComponent
  ],
  imports: [
    CommonModule,
  ],  
  exports: [
    NgxSpinnerModule,
    FormsModule,
    AccountTransactionComponent,
    RideChargeDetailComponent,
    RideInvoiceComponent
  ]
})
export class SharedModule { }
