import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpHandler } from '@angular/common/http';

//Layout Modules
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

// Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Sidebar_Directives } from './shared/directives/side-nav.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Routing Module
import { AppRoutes } from './app.routing';

// App Component
import { AppComponent } from './app.component';

import { ArchwizardModule } from 'angular-archwizard';
import { MenuListItemComponent } from './common/menu-item/menu-item.component';
import { NavService } from './common/menu-item/nav-item.service';

import { NgSelectizeModule } from 'ng-selectize';

import { JuuRideAppInterceptor } from './interceptors/httpinterceptors';
// import { JuuRideDriverInterceptor } from './interceptors/httpinterceptors';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {ToastyModule} from 'ng2-toasty';
import { ProjectLayoutComponent } from './common/project-layout.component';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SharedModule } from './shared/shared/shared.module';
import { HealthcheckComponent } from './components/health-check/healthcheck/healthcheck.component';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(AppRoutes, { useHash: true }),
        NgbModule.forRoot(),
        NgxImageZoomModule.forRoot() ,
        ToastyModule.forRoot(),
        FormsModule,
        PerfectScrollbarModule,
        ArchwizardModule,
        NgSelectizeModule,
        HttpClientModule,
        AutoCompleteModule,
        SharedModule
    ],
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        AuthenticationLayoutComponent,
        ProjectLayoutComponent,
        Sidebar_Directives,
        MenuListItemComponent,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JuuRideAppInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: JuuRideDriverInterceptor, multi: true },
        HttpClient, NavService],
    bootstrap: [AppComponent]
})


export class AppModule { }
