import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ENDPOINTS, 
  SUPPORT_BASE_URL_ENDPOINT } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class RidesService {

  constructor(private httpClient: HttpClient) { }

  // get ride details
  getRideDetailsApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.RIDE_DETAILS}`, payload).pipe(map((data: any) => {
      return data.result;
    }));
  }
  
  //get ride invoice
  getRideInvoiceApi(payload) {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.RIDE_INVOICE}`, payload).pipe(map((data: any) => {
      return data.result;
    }));    
  }

  // get ride transaction details / charge details
  getRideTxnDetails(entityType, payload) {
    if(entityType == 3) {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_RIDE_TXN}`, payload).pipe(map((data: any) => {
        return data.result;
      })); 
    }
    else if(entityType == 4) {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_RIDE_TXN}`, payload).pipe(map((data: any) => {
        return data.result;
      })); 
    }   
  }
}
