import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AddArticleComponent } from './components/add-article/add-article.component';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { ProjectLayoutComponent } from './common/project-layout.component';
import { AuthGuard } from './auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'juurideadmin',
    pathMatch: 'full',
  },
  {
    path: 'juurideadmin',
    component: AuthenticationLayoutComponent,
    children: [
        {
          path: '',
          redirectTo: 'authentication',
          pathMatch: 'full',
        },
        {
          path: 'authentication',
          loadChildren: './components/authentication/authentication.module#AuthenticationModule'
        }
    ]
  },
  {
    path: '',
    component: CommonLayoutComponent,
    children: [
      {
        path: 'driver',
        canActivate: [AuthGuard],
        loadChildren: './components/driver-onboarding/driver-onboarding.module#DriverDashboardModule',
      },
      {
        path: 'vehicle',
        canActivate: [AuthGuard],
        loadChildren: './components/vehicle-onboarding/vehicle-onboarding.module#VehicleOnboardingModule',
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: './components/customer/customer.module#CustomerModule',
      },
      {
        path: 'complaints',
        canActivate: [AuthGuard],
        loadChildren: './components/complaints/complaints.module#ComplaintsModule',
      },
      {
        path: 'support',
        canActivate: [AuthGuard],
        loadChildren: './components/support/support.module#SupportModule',
      },
      {
        path:'admin',
        canActivate: [AuthGuard],
        loadChildren: './components/administration/administration.module#AdministrationModule',
      },
      {
        path:'rides',
        canActivate: [AuthGuard],
        loadChildren: './components/rides/rides.module#RidesModule',
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: './components/reports/reports.module#ReportsModule',
      },
      {
        path: 'finance',
        canActivate: [AuthGuard],
        loadChildren: './components/finance/finance.module#FinanceModule',
      },
      {
        path: 'refunds',
        canActivate: [AuthGuard],
        loadChildren: './components/refunds/refunds.module#RefundsModule',
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './components/dashboard/dashboard.module#DashboardModule',
      },
    ]
  },
  {
    path: 'healthcheck',
    loadChildren: './components/health-check/health-check.module#HealthCheckModule',
  },
];

