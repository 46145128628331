import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';
import { NavService } from './nav-item.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ignoreElements } from 'rxjs/operators';

@Component({
    selector: 'app-menu-list-item',
    templateUrl: './menu-item.component.html',
    // styleUrls: ['./menu-item.component.scss'],

})
export class MenuListItemComponent implements OnInit {
    expanded: boolean;
    @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
    @Input() item: NavItem;
    @Input() depth: number;

    constructor(public navService: NavService,
        public router: Router) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    ngOnInit() {
        this.navService.currentUrl.subscribe((url: string) => {
            if (this.item.route && url) {
                // console.log(`Checking '/${this.item.route}' against '${url}'`);
                this.expanded = url.indexOf(`/${this.item.route}`) === 0;
                this.ariaExpanded = this.expanded;
                // console.log(`${this.item.route} is expanded: ${this.expanded}`);
            }
        });
    }

    onItemSelected(item: NavItem) {
        if (!item.children || !item.children.length) {
            this.router.navigate([item.route]);
            if (this.navService.isMobile) {
                this.navService.closeNav();
            }

        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }
    }

    onExpand(item){
        item.expanded = !item.expanded;
    };

    navToroute(item){
        this.router.navigate([item.url]);
    }
}
